<template>
  <div>
    <v-row justify="space-between" align="stretch" class="my-15 px-5">
      <!-- 新增白名單(可使用TBT兌換USDT) -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-space-between align-center px-10 h-full">
          <div class="d-flex flex-column align-center">
            <h2 class="primary--text">{{ $t('addWhiteList') }}</h2>
            <div class="primary--text mb-10">({{ $t('toTBT') }})</div>
          </div>
          <div class="w-full d-flex flex-column align-center">
            <div>{{ $t('enterWhiteList') }}</div>
            <v-form ref="addWhiteListForm" class="w-full" lazy-validation>
              <v-text-field
                class="my-1"
                v-model="addWhiteListAddress"
                outlined
                dense
                color="primary"
                :label="$t('enterAddWhiteList')"
                :rules="[...accountRules]"
              >
              </v-text-field>
            </v-form>
          </div>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(0)"></btn>
        </v-card>
      </v-col>
      <!-- 調整匯率 -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-center align-center px-10">
          <h2 class="primary--text mb-10">{{ $t('editRate') }}</h2>
          <div>{{ $t('enterUSDTRate') }}</div>
          <v-form ref="USDTRateForm" lazy-validation class="d-flex align-center mb-2 w-full">
            <div>1 USDT : </div>
            <v-text-field
              class="my-1 mx-2"
              style="width: 50px;"
              v-model="usdtRate"
              outlined
              dense
              hide-details
              color="primary"
              :rules="[...TokenAmountRules]"
            >
            </v-text-field>
            <div>TBT</div>
          </v-form>

          <btn class="mb-7" :buttonText="'confirm'" @clickBtn="showConfirm(1)"></btn>

          <div>{{ $t('enterTBTRate') }}</div>
          <v-form ref="TBTRateForm" lazy-validation class="d-flex align-center mb-2 w-full">
            <v-text-field
              class="my-1 mx-2"
              style="width: 50px;"
              v-model="tbtRate"
              outlined
              dense
              hide-details
              color="primary"
              :rules="[...TokenAmountRules]"
            >
            </v-text-field>
            <div>TBT : 1 USDT</div>
          </v-form>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(2)"></btn>
        </v-card>
      </v-col>
      <!--<v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-center align-center px-10">
          <h2 class="primary--text mb-10">{{ $t('editRate') }}</h2>
          <div>{{ $t('enterUSDTRate') }}</div>
          <v-form ref="USDTRateForm" lazy-validation class="d-flex align-center mb-7 w-full">
            <div>1 USDT : </div>
            <v-text-field
              class="my-1 mx-2"
              style="width: 50px;"
              v-model="tbtRate"
              outlined
              dense
              hide-details
              color="primary"
              :rules="[...TokenAmountRules]"
            >
            </v-text-field>
            <div>TBT</div>
          </v-form>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(2)"></btn>
        </v-card>
      </v-col>-->

      <!-- 刪除白名單(可使用TBT兌換USDT) -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-space-between align-center px-10 h-full">
          <div class="d-flex flex-column align-center">
            <h2 class="primary--text">{{ $t('deleteWhiteList') }}</h2>
            <div class="primary--text mb-10">({{ $t('toTBT') }})</div>
          </div>
          <div class="w-full d-flex flex-column align-center">
            <div>{{ $t('enterWhiteList') }}</div>
            <v-form ref="deleteWhiteListForm" class="w-full" lazy-validation>
              <v-text-field
                class="my-1"
                v-model="deleteWhiteListAddress"
                outlined
                dense
                color="primary"
                :label="$t('enterDeleteWhiteList')"
                :rules="[...accountRules]"
              >
              </v-text-field>
            </v-form>
          </div>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(3)"></btn>
        </v-card>
      </v-col>
      <!-- 新增白名單(可使用USDT兌換TBT) -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-space-between align-center px-10 h-full">
          <div class="d-flex flex-column align-center">
            <h2 class="primary--text">{{ $t('addWhiteList') }}</h2>
            <div class="primary--text mb-10">({{ $t('toUSDT') }})</div>
          </div>
          <div class="w-full d-flex flex-column align-center">
            <div>{{ $t('enterWhiteList') }}</div>
            <v-form ref="addTBTWhiteListForm" class="w-full" lazy-validation>
              <v-text-field
                class="my-1"
                v-model="addTBTWhiteListAddress"
                outlined
                dense
                color="primary"
                :label="$t('enterAddWhiteList')"
                :rules="[...accountRules]"
              >
              </v-text-field>
            </v-form>
          </div>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(4)"></btn>
        </v-card>
      </v-col>
      <!-- 設定抽出的趴數 -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-space-between align-center px-10 h-full">
          <h2 class="primary--text">{{ $t('setProfitRate') }}</h2>
          <div class="w-full d-flex flex-column align-center">
            <div>{{ $t('enterProfitRate') }}</div>
            <v-form ref="profitRateForm" class="w-full" lazy-validation>
              <v-text-field
                class="my-1"
                v-model="profitRate"
                outlined
                dense
                hide-details
                color="primary"
                :rules="[...TokenAmountRules]"
                append-icon="%"
              >
              </v-text-field>
            </v-form>
          </div>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(6)"></btn>
        </v-card>
      </v-col>
      <!-- 刪除白名單(可使用USDT兌換TBT) -->
      <v-col cols="12" sm="6" md="4">
        <v-card light class="admin-card card-wrap d-flex flex-column justify-space-between align-center px-10 h-full">
          <div class="d-flex flex-column align-center">
            <h2 class="primary--text">{{ $t('deleteWhiteList') }}</h2>
            <div class="primary--text mb-10">({{ $t('toUSDT') }})</div>
          </div>
          <div class="w-full d-flex flex-column align-center">
            <div>{{ $t('enterWhiteList') }}</div>
            <v-form ref="deleteTBTWhiteListForm" class="w-full" lazy-validation>
              <v-text-field
                class="my-1"
                v-model="deleteTBTWhiteListAddress"
                outlined
                dense
                color="primary"
                :label="$t('enterDeleteWhiteList')"
                :rules="[...accountRules]"
              >
              </v-text-field>
            </v-form>
          </div>

          <btn :buttonText="'confirm'" @clickBtn="showConfirm(5)"></btn>
        </v-card>
      </v-col>
    </v-row>
    <warning :warningShow="warningShow" :confirmText="warningContent[type].confirmText" @confirm="confirm()" @close="warningShow = false">
      <template slot="title">{{ $t(warningContent[type].title) }}</template>
      <template slot="inputTitle">{{ $t(warningContent[type].inputTitle) }}</template>
      <template slot="content">{{ $t(warningContent[type].content) }}</template>
    </warning>
    <loading :loadingShow="loadingShow" :content="'waitSetting'"></loading>
  </div>
</template>
<script>
import btn from '@/components/btn.vue'
import warning from '@/components/warning.vue'
import loading from '@/components/loading.vue'
import base from '@/mixin/base'
export default {
  name: "Admin",
  mixins: [base],
  data(){
    return {
      addWhiteListAddress: '',
      deleteWhiteListAddress: '',
      addTBTWhiteListAddress: '',
      deleteTBTWhiteListAddress: '',
      usdtRate: '',
      tbtRate: '',
      profitRate: '',
      warningShow: false,
      type: 0,
      timer: null,
      loadingShow: false,
      warningContent: [
        {
          title: 'addWhiteList',
          inputTitle: 'addWhiteListAddress',
          content: '',
          confirmText: 'add',
        },
        {
          title: 'editRate',
          inputTitle: 'newUSDTRate',
          content: '',
          confirmText: 'edit',
        },
        {
          title: 'editRate',
          inputTitle: 'newTBTRate',
          content: '',
          confirmText: 'edit',
        },
        {
          title: 'deleteWhiteList',
          inputTitle: 'deleteWhiteListAddress',
          content: '',
          confirmText: 'delete',
        },
        {
          title: 'addWhiteList',
          inputTitle: 'addWhiteListAddress',
          content: '',
          confirmText: 'add',
        },
        {
          title: 'deleteWhiteList',
          inputTitle: 'deleteWhiteListAddress',
          content: '',
          confirmText: 'delete',
        },
        {
          title: 'setProfitRate',
          inputTitle: 'newProfitRate',
          content: '',
          confirmText: 'edit',
        },
      ]
    }
  },
  components:{
    btn,
    warning,
    loading
  },
  methods:{
    showConfirm(type){
      this.type = type
      if (type === 0){
        if (this.$refs.addWhiteListForm.validate()){
          this.warningShow = true
          this.warningContent[0].content = window.innerWidth > 600 ? this.addWhiteListAddress : `${this.addWhiteListAddress.slice(0, 6)}...${this.addWhiteListAddress.slice(38)}`
        }
      }else if (type === 1){
        if (this.$refs.USDTRateForm.validate()){
          this.warningShow = true
          this.warningContent[1].content = `1 USDT : ${this.usdtRate} TBT`
        }
      }else if (type === 2){
        if (this.$refs.TBTRateForm.validate()){
          this.warningShow = true
          this.warningContent[2].content = `${this.tbtRate} TBT : 1 USDT`
        }
      }else if (type === 3){
        if (this.$refs.deleteWhiteListForm.validate()){
          this.warningShow = true
          this.warningContent[3].content = window.innerWidth > 600 ? this.deleteWhiteListAddress : `${this.deleteWhiteListAddress.slice(0, 6)}...${this.deleteWhiteListAddress.slice(38)}`
        }
      }else if (type === 4){
        if (this.$refs.addTBTWhiteListForm.validate()){
          this.warningShow = true
          this.warningContent[4].content = window.innerWidth > 600 ? this.addTBTWhiteListAddress : `${this.addTBTWhiteListAddress.slice(0, 6)}...${this.addTBTWhiteListAddress.slice(38)}`
        }
      }else if (type === 5){
        if (this.$refs.deleteTBTWhiteListForm.validate()){
          this.warningShow = true
          this.warningContent[5].content = window.innerWidth > 600 ? this.deleteTBTWhiteListAddress : `${this.deleteTBTWhiteListAddress.slice(0, 6)}...${this.deleteTBTWhiteListAddress.slice(38)}`
        }
      }else if (type === 6){
        if (this.$refs.profitRateForm.validate()){
          this.warningShow = true
          this.warningContent[6].content = `${this.profitRate}%`
        }
      }
    },
    confirm(){
      if (this.type === 0){
        this.addWhiteList('toTbt')
      }else if (this.type === 1){
        this.editRate('usdt')
      }else if (this.type === 2){
        this.editRate('tbt')
      }else if (this.type === 3){
        this.deleteWhiteList('toTbt')
      }else if (this.type === 4){
        this.addWhiteList('toUsdt')
      }else if (this.type === 5){
        this.deleteWhiteList('toUsdt')
      }else if (this.type === 6){
        this.editProfitRate()
      }
    },
    async addWhiteList(type){
      if (this.$store.state.chainId){
        let result = await this.$defi.addWhiteList(type, type==='toTbt' ? this.addWhiteListAddress : this.addTBTWhiteListAddress)
        // console.log('result', result)
        if (result === 'isWhiteList'){
          this.$toasted.show(this.$t('alreadyIsWhiteList'))
        }else{
          if (result.txHash){
            this.$toasted.show(this.$t('txSend'))
            this.warningShow = false
            this.$refs.addWhiteListForm.reset()
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async deleteWhiteList(type){
      if (this.$store.state.chainId){
        let result = await this.$defi.deleteWhiteList(type, type==='toTbt' ? this.deleteWhiteListAddress : this.deleteTBTWhiteListAddress)
        // console.log('result', result)
        if (result === 'isNotWhiteList'){
          this.$toasted.show(this.$t('alreadyNotWhiteList'))
        }else{
          if (result.txHash){
            this.$toasted.show(this.$t('txSend'))
            this.warningShow = false
            this.$refs.deleteWhiteListForm.reset()
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async editRate(type){
      if (this.$store.state.chainId){
        let rate = type === 'usdt' ? this.usdtRate : this.tbtRate
        let result = await this.$defi.editRate(type, rate)
        // console.log('result', result)
        if (result.txHash){
          this.$toasted.show(this.$t('txSend'))
          this.warningShow = false
          this.$refs.USDTRateForm.reset()
          this.$refs.TBTRateForm.reset()
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async getProfitRate(){
      this.profitRate = await this.$defi.getProfitRate()
    },
    async editProfitRate(){
      if (this.$store.state.chainId){
        let result = await this.$defi.editProfitRate(this.profitRate/100)
        // console.log('result', result)
        if (result.txHash){
          this.$toasted.show(this.$t('txSend'))
          this.loadingShow = true
          this.warningShow = false
          let oldProfitRate = this.profitRate
          this.timer = window.setInterval(async () => {
            await this.getProfitRate()
            if (this.profitRate >= oldProfitRate) {
              window.clearInterval(this.timer)
              this.loadingShow = false
            }
          }, 1000)
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
  },
  async mounted(){
    await this.getProfitRate()
  }
}
</script>